// project import
import jobstatus from './jobstatus';
import utilities from './utilities';
import support from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [jobstatus, utilities, support]
};

export default menuItems;
