// assets
import { ChromeOutlined,GlobalOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  GlobalOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const jobstatus = {
  id: 'job-dashboard',
  title: 'Indexing Status',
  type: 'group',
  children: [
    {
      id: 'index-na-prod',
      title: 'Prod / North America',
      type: 'item',
      url: '/jobs/prod/north-america',
      icon: icons.ChromeOutlined
    },
    {
      id: 'index-intl-prod',
      title: 'Prod / International',
      type: 'item',
      url: '/jobs/prod/international',
      icon: icons.GlobalOutlined
    },{
      id: 'index-na-preprod',
      title: 'PreProd / North America',
      type: 'item',
      url: '/jobs/preprod/north-america',
      icon: icons.ChromeOutlined
    },
    {
      id: 'index-intl-preprod',
      title: 'PreProd / International',
      type: 'item',
      url: '/jobs/preprod/international',
      icon: icons.GlobalOutlined
    },{
      id: 'index-na-qa',
      title: 'QA / North America',
      type: 'item',
      url: '/jobs/qa/north-america',
      icon: icons.ChromeOutlined
    },
    {
      id: 'index-intl-qa',
      title: 'QA / International',
      type: 'item',
      url: '/jobs/qa/international',
      icon: icons.GlobalOutlined
    },{
      id: 'index-na-stg',
      title: 'Stg / North America',
      type: 'item',
      url: '/jobs/stg/north-america',
      icon: icons.ChromeOutlined
    },
    {
      id: 'index-intl-stg',
      title: 'Stg / International',
      type: 'item',
      url: '/jobs/stg/international',
      icon: icons.GlobalOutlined
    }
  ]
};

export default jobstatus;
