import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const ManageAssetsDefault = Loadable(lazy(() => import('pages/manage-assets')));
const JobTable = Loadable(lazy(() => import('pages/indexing-job-status')));
const MainDefault = Loadable(lazy(() => import('pages/main')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/assets/:assetType',
      element: <ManageAssetsDefault />,
      children: [
        {
          path: '/assets/:assetType/:assetId',
          element: <ManageAssetsDefault />
        }
      ]
    },
    {
      path: '/jobs/:env/:regn',
      element: <JobTable />,
      children: [
        {
          path: '/jobs/:env/:regn/:assetId',
          element: <JobTable />
        }
      ]
    },
    {
      path: '/main',
      element: <MainDefault />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    }
  ]
};

export default MainRoutes;
